import { Component, OnInit } from "@angular/core";
import { Observable } from 'rxjs';
import { doc, getDoc, query, setDoc } from "firebase/firestore"; 
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { environment } from '../../../environments/environment.prod';
const firebaseApp = initializeApp({
  apiKey: environment.firebase.apiKey,
  authDomain: environment.firebase.authDomain,
  projectId: environment.firebase.projectId
});

const db = getFirestore();
@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
})
export class IndexComponent implements OnInit {
  public uniqueCode ='';
  public showAlert = false;
  showModal = false;
  data = {};
  todayDate;
  constructor() {
    this.todayDate = new Date();
  }
  ngOnInit(): void {}
  checkAuthenticity(){
    this.data = {};
    this.showAlert = false;
    this.showModal = false;
    const docRef = doc(db, "auth",this.uniqueCode);
    getDoc(docRef).then(resp=>{
      if(resp.data() !== undefined){
        this.showModal = true;
        this.data = resp.data();
      }
      else{
        this.showAlert = true;
      }
      this.uniqueCode = '';
    });
  }
} 