import { Component, OnInit } from '@angular/core';
import { doc, getDoc, query, setDoc } from "firebase/firestore"; 
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { environment } from '../../../environments/environment.prod';
import html2canvas from 'html2canvas';


const firebaseApp = initializeApp({
  apiKey: environment.firebase.apiKey,
  authDomain: environment.firebase.authDomain,
  projectId: environment.firebase.projectId
});

const db = getFirestore();

@Component({
  selector: 'app-check-authenticity',
  templateUrl: './check-authenticity.component.html',
  styleUrls: ['./check-authenticity.component.css']
})

export class CheckAuthenticityComponent implements OnInit {
  public uniqueCode ='';
  public showAlert = false;
  showModal = false;
  data = {};
  todayDate;
  isFlipped = false;
  constructor() {
    this.todayDate = new Date();
  }
  ngOnInit(): void {}
  checkAuthenticity(){
    this.data = {};
    this.showAlert = false;
    this.showModal = false;
    this.isFlipped = true;
    const docRef = doc(db, "auth",this.uniqueCode);
    getDoc(docRef).then(resp=>{
      if(resp.data() !== undefined){
        this.showModal = true;
        this.data = resp.data();
      }
      else{
        this.showAlert = true;
      }
    });
  }
  reset(){
    this.isFlipped = false;
    this.data = {};
    this.showAlert = false;
    this.showModal = false;
    this.uniqueCode = '';
  }
  screenshot() {
    const captureElement = document.querySelector("#capture") as HTMLElement;
    html2canvas(captureElement,{ scale: 2 }).then((canvas: HTMLCanvasElement) => {
      const imageData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.setAttribute("download", "bview-auth-card-"+this.uniqueCode+".png");
      link.setAttribute("href", imageData);
      link.click();
    });
  }
}