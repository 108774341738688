export const environment = {
  firebase: {
    projectId: 'bview-efa62',
    appId: '1:55799752580:web:90dea36ef4f01872d01fd1',
    storageBucket: 'bview-efa62.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyDOYP0KrdrYuD1sDsnraI1uGz6-oqMnWyA',
    authDomain: 'bview-efa62.firebaseapp.com',
    messagingSenderId: '55799752580',
    measurementId: 'G-9P2RDX8HSW',
  },
  production: true,
};
