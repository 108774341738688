import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-index-navbar",
  templateUrl: "./index-navbar.component.html",
})
export class IndexNavbarComponent implements OnInit {
  navbarOpen = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }

  smoothScroll(elementDest) {
    if(elementDest==='top'){
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
    else{
      this.router.navigate(['/']);
      setTimeout(() => {
      var element = document.querySelector("#" + elementDest);
      var headerOffset = 100;
      if (window.innerWidth > 1024) {
        headerOffset = 0;
      }
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
      this.navbarOpen = !this.navbarOpen;
    },0);
    }
  }
}
